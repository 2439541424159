<template>
  <div class="col">
    <server-table
      :count="getCareerStrategiesCount"
      :DataItems="getCareerStrategies"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'Career Strategies'"
      admin_permission="admin_tasks"
      edit_permission="add_tasks"
      import_url="career/strategies/import"
      @export="exportCareerStrategies"
    >
      <template #columns>
        <el-table-column prop="strategy" label="strategy"></el-table-column>
        <el-table-column
          prop="classification"
          label="classification"
        ></el-table-column>
        <el-table-column prop="pattern" label="pattern"></el-table-column>
        <el-table-column prop="topic_name" label="topic"></el-table-column>
        <el-table-column prop="percentage" label="percentage"></el-table-column>

        <el-table-column prop="" label="_">
          <template slot-scope="scope">
            <div class="main_actions">
              <i
                @click="showStrategy(scope.row.id)"
                v-tooltip.top-center="'Show Strategy'"
                class="fa fa-eye actions-icons add"
              ></i>
              <i
                @click="openEditDialog(scope.row)"
                v-tooltip.top-center="'Edit Strategy'"
                class="fa fa-edit  actions-icons edit"
              ></i>
              <i
                @click="removeStatement(scope.row.id)"
                v-tooltip.top-center="'Delete Strategy'"
                class="fa fa-times actions-icons delete"
              ></i>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <div
          style="position:relative"
          @click="showDialog = true"
          wide
          class="text-capitalize"
        >
          <el-icon class="el-icon-plus"></el-icon>
          Add Career Strategy
        </div>
      </template>
    </server-table>

    <!-- Start -->
    <el-dialog
      :title="this.type == 1 ? `Add Career Strategy` : `Edit Career Strategy`"
      :visible.sync="showDialog"
      width="60%"
      top="4vh"
    >
      <el-form
        :model="statementsData"
        ref="statementsRef"
        :rules="statementsDataRules"
        label-position="top"
      >
        <div class="row">
          <div class="col-md-6">
            <el-form-item label="Strategy" prop="strategy">
              <el-input v-model="statementsData.strategy"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item label="Language" prop="language">
              <el-select v-model="statementsData.language" style="width: 100%">
                <el-option
                  v-for="item in getCareerStrategiesLanguages"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item label="Topic" prop="topic_id">
              <el-select v-model="statementsData.topic_id" style="width: 100%">
                <el-option
                  v-for="item in getCareerStrategiesTopics"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item label="Pattern" prop="pattern">
              <el-select v-model="statementsData.pattern" style="width: 100%">
                <el-option
                  v-for="(item, index) in patterns"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item label="Classification" prop="classification">
              <el-select
                v-model="statementsData.classification"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in classifications"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item label="Percentage" prop="percentage">
              <el-select
                v-model="statementsData.percentage"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in percentages"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div
          class="
                  dialog-footer
                  dialog_footer
                "
        >
          <el-button
            class="text-center text-capitalize save_button delete_button"
            @click="showDialog = false"
          >
            {{ localization("Cancel") }}
          </el-button>
          <el-button
            type=""
            class="
                    text-center
                    text-capitalize
                    save_button
                  "
            @click="saveCareerStatementsQuestions"
          >
            Save
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- End -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      filter: {},
      showDialog: false,
      type: 1,
      statementsQuestionsId: null,
      statementsData: {
        strategy: null,
        topic_id: null,
        language: null,
        pattern: null,
        classification: null,
        percentage: null
      },
      statementsDataRules: {
        strategy: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        pattern: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        classification: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        percentage: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      patterns: [
        { label: "Confluence" },
        { label: "Precision" },
        { label: "Sequence" },
        { label: "Technical Reasoning" }
      ],
      classifications: [
        { label: "Use First" },
        { label: "As Needed" },
        { label: "Avoid" }
      ],
      percentages: [{ label: "25-100%" }, { label: "1-24%" }, { label: "0%" }]
    };
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("user/handlerCareerStrategies", {
        query: query
      });
    },
    openDialog() {
      this.showDialog = true;
    },
    openEditDialog(item) {
      this.showDialog = true;
      this.type = 2;
      this.statementsData.strategy = item.strategy;
      this.statementsData.percentage = item.percentage;
      this.statementsData.topic_id = item.topic_id;
      this.statementsData.language = item.language;
      this.statementsData.pattern = item.pattern;
      this.statementsData.classification = item.classification;
      this.statementsQuestionsId = item.id;
    },
    removeStatement(id) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/handleDeleteCareerStrategies", id)
            .then(_ => {
              this.Refresh(null);
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    saveCareerStatementsQuestions() {
      this.$refs["statementsRef"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("user/handleAddrCareerStrategies", {
                query: this.statementsData
              })
              .then(_ => {
                this.showDialog = false;
                this.Refresh();
                this.statementsData.strategy = null;
                this.statementsData.percentage = null;
                this.statementsData.topic_id = null;
                this.statementsData.language = null;
                this.statementsData.pattern = null;
                this.statementsData.classification = null;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("user/handleUpdateCareerStrategies", {
                id: this.statementsQuestionsId,
                query: this.statementsData
              })
              .then(_ => {
                this.showDialog = false;
                this.Refresh();
                this.statementsData.strategy = null;
                this.statementsData.percentage = null;
                this.statementsData.topic_id = null;
                this.statementsData.language = null;
                this.statementsData.pattern = null;
                this.statementsData.classification = null;
                this.statementsQuestionsId = null;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        }
      });
    },
    showStrategy(id) {
      this.$router.push(`career-strategies/${id}`);
    },
    exportCareerStrategies(query) {
      this.$store.dispatch("user/handlerExportCareerStrategies", {
        query: query
      });
    }
  },
  computed: {
    getCareerStrategies() {
      return this.$store.getters["user/getCareerStrategies"];
    },
    getCareerStrategiesCount() {
      return this.$store.getters["user/getCareerStrategiesCount"];
    },
    getCareerStrategiesTopics() {
      return this.$store.getters["user/getCareerStrategiesTopics"];
    },
    getCareerStrategiesLanguages() {
      return this.$store.getters["user/getCareerStrategiesLanguages"];
    }
  },
  components: {
    ServerTable
  }
};
</script>

<style lang="scss" scoped>
.main_actions {
  display: flex;
  padding: 5px 0;
  gap: 10px;
  .actions-icons {
    font-size: 14px;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
    color: #777;
    transition: 0.3s;
    &.team_count {
      background-color: #11bfe3;
      color: #fff;
    }
    &.add {
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
    &.edit {
      &:hover {
        background-color: #ffa534;
        color: #fff;
      }
    }
    &.reinvite {
      &:hover {
        background-color: #3c3;
        color: #fff;
      }
    }
    &.delete {
      &:hover {
        background-color: #fb404b;
        color: #fff;
      }
    }
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    // background-color: #23ccef;
    border-color: #00563f;
    background-color: #00563f;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>
